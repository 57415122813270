import { PaginationMeta } from "common/utils/types";

export type IssuesMeta = PaginationMeta & {
  stale_total: number;
};

export interface Issues {
  issues: any[];
  loading: boolean;
  meta: IssuesMeta;
  hcpTeamIds?: number[];
}

export const defaultIssuesShape: Issues = {
  issues: [],
  loading: true,
  meta: { page: 1, per_page: 5, total: 0, total_pages: 1, stale_total: 0 },
};
