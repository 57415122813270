import styled from "styled-components";
import rtl from "styled-components-rtl";
import { Badge } from "@netmedi/frontend-design-system";
import textStyles from "@netmedi/frontend-design-system/dist/styles/textStyle";

const ContentAreaBadge = styled(Badge)`
  font-weight: ${textStyles.mediumFontWeight};
  border-radius: 40%;
  ${rtl`
    margin-left: 1ex;
    margin-right: 0px;
  `}
`;

export const HeaderText = styled.h2`
  font-size: ${textStyles.extraLargeTextSize};
  margin: 0.25rem 0;
  font-weight: 500;
`;

export default ContentAreaBadge;
