import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import {
  colors,
  mediaQueries,
  scaffolding,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import {
  mediaMin,
  mediaMax,
} from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";
import { ListItem } from "common/components/List";
import { Header } from "common/components/Page";

interface ITimeInfo {
  bottomMargin?: boolean;
}

const { pagePadding } = scaffolding;

// the default styling doesn't work on mobile with the nav tabs
export const CalendarHeader = styled(Header)`
  ${mediaMax(mediaQueries.small)} {
    padding: ${pagePadding};
  }
`;

// !important is needed due to SCSS styling on HCP side
const calendarListItemStyles = css`
  ${mediaMin(mediaQueries.medium)} {
    max-width: 760px;
    margin: 0 auto 0 auto !important;
  }
`;

export const CalendarListItem = styled(ListItem)`
  ${calendarListItemStyles}
`;

export const YearDivider = styled.div`
  ${calendarListItemStyles}
  padding: 2rem 0;
  font-size: 1.2rem;
`;

export const EntryContainer = styled.div`
  ${rtl`text-align: left`};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 1rem;
  strong {
    font-size: 1.2rem;
  }
`;

export const TitleAndOther = styled.div`
  ${mediaMin(mediaQueries.small + 1)} {
    display: flex;
    justify-content: space-between;
  }
  width: 65%;
`;
export const EventInfo = styled.div`
  width: 50%;
  ${mediaMax(mediaQueries.small)} {
    width: 100%;
  }
`;

export const TimeInfo = styled.div.attrs(() => ({
  "data-testid": "calendar-event-field",
}))<ITimeInfo>`
  width: 30%;
  ${({ bottomMargin }) => bottomMargin && `margin-bottom: 0.5rem;`}
`;

export const Container = styled.div`
  overflow-y: auto;
`;

export const NoEventsText = styled.div`
  padding: 10px 0 20px 0;
  color: ${colors.gray700};
  ${mediaMax(mediaQueries.small)} {
    text-align: center;
  }
`;

export const CalendarPreviewContent = styled.div`
  text-align: center;
  a {
    font-weight: 500;
  }
`;
