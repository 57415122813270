import { useEffect, useState } from "react";

import { useSelector } from "store";
import { useErrorBoundary } from "common/utils/errorBoundary";
import { Id } from "common/utils/types";
import { get, endpoints, parseJson } from "common/utils/api";
import {
  CalendarEntry,
  CalendarResponse,
  EntryTime,
} from "shared/models/calendarEntries";

type Params = {
  clientId?: Id;
  time: EntryTime;
};

export const useCalendar = ({ clientId, time }: Params) => {
  const userId = useSelector(state => state.user.id);
  const [entries, setEntries] = useState<CalendarEntry[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState(true);

  const { showBoundary } = useErrorBoundary();

  // set page to 1 when switching to different time range of appointments
  useEffect(() => {
    setPage(1);
  }, [time]);

  useEffect(() => {
    setLoading(true);
    get<CalendarResponse>(
      endpoints.appointments(clientId ?? userId, {
        time,
        "page[number]": page,
      }),
      false,
      {},
      { throwErrors: true },
    )
      .then(data => parseJson(data))
      .then(({ data, total_pages }) => {
        setEntries(data);
        setTotalPages(total_pages);
        setLoading(false);
      })
      .catch(showBoundary);
  }, [clientId ?? userId, time, page]);

  return { entries, page, setPage, totalPages, loading };
};
