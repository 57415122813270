import React from "react";

import { ErrorBoundary } from "common/utils/errorBoundary";
import { Container } from "./Calendar.styles";
import { AllEntries, Props } from "./AllEntries";
import { useSelector } from "store";
import CalendarEntries from "client/components/WelcomeClient/CalendarEntries";

const CalendarEntriesContainer = (props: Required<Props>) => {
  const newCalendarView = useSelector(
    ({ user }) => user.features.new_calendar_view,
  );
  return (
    <Container>
      <ErrorBoundary>
        {newCalendarView ? (
          <AllEntries {...props} />
        ) : (
          <CalendarEntries {...props} />
        )}
      </ErrorBoundary>
    </Container>
  );
};

export default CalendarEntriesContainer;
