import React from "react";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Id } from "common/utils/types";
import Page, { NavItem, PageNav } from "common/components/Page";
import Panel from "common/components/Panel";
import { EntryList } from "./EntryList";
import { useCalendar } from "./useCalendar";
import { EntryTime } from "shared/models/calendarEntries";
import { SensiblePagination } from "common/components/Pagination";
import { CalendarHeader, NoEventsText } from "./Calendar.styles";
import { Spinner } from "@netmedi/frontend-design-system";

export type Props = { clientId?: Id; time: EntryTime; holvikaari?: boolean };

export const AllEntries = ({ clientId, time, holvikaari }: Props) => {
  const { entries, page, setPage, totalPages, loading } = useCalendar({
    clientId,
    time,
  });

  const renderLink = (targetTime: EntryTime) => {
    const linkText = <FormattedMessage id={`calendar.${targetTime}`} />;

    return (
      <NavItem active={time === targetTime}>
        {holvikaari ? (
          <a href={`calendar?time=${targetTime}`}>{linkText}</a>
        ) : (
          <Link to={targetTime}>{linkText}</Link>
        )}
      </NavItem>
    );
  };

  return (
    <Page>
      <CalendarHeader
        linkBack={holvikaari ? undefined : "back"}
        linkBackRouteOverride="/"
        title="calendar.title"
      >
        <div>
          <PageNav tabs>
            {renderLink("upcoming")}
            {renderLink("past")}
          </PageNav>
        </div>
      </CalendarHeader>
      <Panel noBorder>
        {loading ? (
          <Spinner />
        ) : entries.length > 0 ? (
          <EntryList entries={entries} showYears />
        ) : (
          <NoEventsText>
            <FormattedMessage id={`calendar.no_${time}`} />
          </NoEventsText>
        )}
      </Panel>
      <SensiblePagination
        move={setPage}
        totalPages={totalPages}
        currentPage={page}
      />
    </Page>
  );
};

export const AllEntriesWithRouter = () => {
  const { time } = useParams<{ time: EntryTime }>();
  return <AllEntries time={time} />;
};
