import styled from "styled-components";
import rtl from "styled-components-rtl";

export const HorizontalDividerContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
`;

export const HorizontalDividerLine = styled.hr.attrs(() => ({
  "aria-hidden": true,
}))<{
  marginLeft?: string;
  marginRight?: string;
}>`
  flex-grow: 1;
  height: 2px;
  ${rtl`
    margin-left: ${({ marginLeft = "0px" }) => marginLeft};
    margin-right: ${({ marginRight = "0px" }) => marginRight};
  `}
`;
