import React from "react";
import dayjs from "dayjs";

import { List } from "common/components/List";
import { SiteSettings } from "common/utils/holvikaari";
import { CalendarEntry as TCalendarEntry } from "shared/models/calendarEntries";
import { CalendarEntry } from "./CalendarEntry";
import { ErrorBoundary } from "common/utils/errorBoundary";
import { YearDivider } from "./Calendar.styles";

type Props = { entries: TCalendarEntry[]; showYears?: boolean };

// validate tz string is not empty and starts with capital letter
const isValidTimezone = (tz: string) =>
  typeof tz === "string" && tz.length > 0 && tz[0] === tz[0].toUpperCase(); // will correctly handle unorthodox cases of tz strings such as blank, undefined or "true"/"false" (testHelper:59)

// check if SiteSettings.always_show_appointment_timezone is true
// or if SiteSettings.time_zone is different from deviceTimezone
const show_all_timezones = (deviceTimezone: string) =>
  !!SiteSettings.always_show_appointment_timezone ||
  (isValidTimezone(SiteSettings.time_zone) &&
    SiteSettings.time_zone !== deviceTimezone); // if SiteSettings.time_zone is set and different from deviceTimezone

export const EntryList = ({ entries, showYears }: Props) => {
  const deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <List spacing="xs" noPadding>
      {entries.map((entry, i) => (
        <React.Fragment key={entry.id}>
          {showYears &&
            i !== 0 &&
            dayjs(entry.begin_at).year() !==
              dayjs(entries[i - 1].begin_at).year() && (
              <YearDivider>
                <strong>{dayjs(entry.begin_at).year()}</strong>
              </YearDivider>
            )}
          <ErrorBoundary>
            <CalendarEntry
              entry={entry}
              deviceTimezone={deviceTimezone}
              show_all_timezones={show_all_timezones(deviceTimezone)}
            />
          </ErrorBoundary>
        </React.Fragment>
      ))}
    </List>
  );
};
