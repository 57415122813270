import React from "react";
import { FormattedMessage } from "react-intl";

import Panel from "common/components/Panel";
import { ErrorBoundary } from "common/utils/errorBoundary";
import { HorizontalDivider } from "./HorizontalDivider";
import ContentAreaBadge, { HeaderText } from "./ContentArea.styles";

interface IProps {
  headingId: string;
  headingValues?: Record<string, string>;
  notifierCount?: number;
  panelType?: "plain" | "highlight";
  panelProps?: Record<string, unknown>;
  panelClassName?: string;
  children?: JSX.Element | JSX.Element[];
  targetId?: string;
}

export const ContentArea = ({
  headingId,
  headingValues,
  notifierCount = 0,
  panelType = "plain",
  panelClassName,
  panelProps,
  children,
  targetId,
}: IProps) => {
  return (
    <Panel
      centered
      noBorder
      spacing2020={!panelProps?.newSpacing}
      type={panelType}
      className={panelClassName}
      heading={
        <HorizontalDivider>
          <HeaderText id={targetId}>
            <FormattedMessage id={headingId} values={headingValues} />
          </HeaderText>
          {notifierCount > 0 ? (
            <ContentAreaBadge label={notifierCount} />
          ) : null}
        </HorizontalDivider>
      }
      {...panelProps}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Panel>
  );
};

export const ContentAreaContext = React.createContext<null | IProps>(null);

// provides the ContentAreaContext, and wraps with ErrorBoundary
export const ContentAreaContextProvider = ({ children, ...props }: IProps) => {
  return (
    <ContentAreaContext.Provider value={props}>
      <ErrorBoundary fallbackWrapper={ContentArea} fallbackWrapperProps={props}>
        {children}
      </ErrorBoundary>
    </ContentAreaContext.Provider>
  );
};

/*
 * Renders children with ContentArea if the context is found, otherwise just the children.
 *
 * Usecase: the headers in WelcomeClient aren't rendered unless there is content to show,
 * but it shouldn't be WelcomeClient's job to check whether it should render its children or not
 * (and doing so makes placing error boundaries difficult). Some children are used outside of
 * WelcomeClient as well however, so they might not always render the WelcomeClient header.
 * Context seemed the most elegant way to resolve this without having to pass the same props twice
 * to the ContentArea in the ErrorBoundary and this ContentArea.
 */
export const ContentAreaWrapper = ({
  children,
}: Required<Pick<IProps, "children">>) => {
  const contentAreaProps = React.useContext(ContentAreaContext);
  return contentAreaProps ? (
    <ContentArea {...contentAreaProps}>{children}</ContentArea>
  ) : (
    // React Fragment in case children is a list
    <>{children}</>
  );
};
