import { Spinner } from "@netmedi/frontend-design-system";
import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Error404 from "common/components/Error404";
import Article from "client/components/PatientSupport/Article.lazy";
import Articles from "client/components/PatientSupport/Articles.lazy";
import CompareAnswers from "client/components/CompareAnswers/CompareAnswers.lazy";
import Feedback from "shared/components/Feedback/Feedback.lazy";
import InputForm from "shared/components/InputForm/InputForm.lazy";
import Profile from "client/components/Profile/Profile.lazy";
import WelcomeClient from "client/components/WelcomeClient/WelcomeClient.lazy";
import { cleanUrlLocalePrefix } from "common/utils/general";
import TimeTracker from "common/utils/timetracker";
import Conversation from "shared/components/Conversation/Conversation.lazy";
import { User } from "common/models/user";
import EmbeddableViews from "common/components/EmbeddableViews/EmbeddableViews.lazy";
import { SiteSettings } from "common/utils/holvikaari";
import ClientDashboard from "client/components/ClientDashboard/ClientDashboard.lazy";
import { AllEntriesWithRouter } from "shared/components/Calendar/AllEntries";

const DelayedSpinner = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <>{show && <Spinner />}</>;
};

const AsyncComponent = ({
  DynamicComponent,
  componentProps = {},
}: AsyncComponentProps) => {
  return (
    <Suspense fallback={<DelayedSpinner />}>
      <DynamicComponent {...componentProps} />
    </Suspense>
  );
};

type AsyncComponentProps = {
  DynamicComponent: React.LazyExoticComponent<React.ComponentType<any>>;
  componentProps?: any;
};

const LazyConversationsList = React.lazy(() =>
  Promise.all([
    import(
      /* webpackChunkName: 'conversations', webpackPreload: true, webpackMode: 'eager' */ "shared/components/ConversationList/ClientConversationList"
    ),
    import(
      /* webpackChunkName: 'conversations', webpackPreload: true, webpackMode: 'eager' */ "shared/reducers/chat"
    ),
  ]).then(vars => vars[0]),
);
const ConversationList = () => (
  <AsyncComponent DynamicComponent={LazyConversationsList} />
);

export const TimeTrackedRoute = (props: TimeTrackedRouteProps) => {
  useEffect(TimeTracker.trackEffect({ ...props.computedMatch, ...props }), [
    props.path,
  ]);
  return <Route {...props} />;
};

type TimeTrackedRouteProps = {
  name: string;
  eventName?: string;
  computedMatch?: any;
  hashingKeys: Record<string, string>;
} & RouteProps;

export const RedirectToNoLocale = () => {
  const history = useHistory();
  const newPath = cleanUrlLocalePrefix(history.location.pathname);
  return <Redirect to={newPath} />;
};

// eslint-disable-next-line max-lines-per-function
export const AllRoutes = ({ user }: { user?: User }) => {
  let conditionalRoutes: any = [];

  if (user !== undefined) {
    if (user.conversations_enabled) {
      conditionalRoutes.push(
        <TimeTrackedRoute
          name="conversations#index"
          key="conversations#index"
          exact
          component={ConversationList}
          path="/conversations"
          hashingKeys={{}}
        />,
      );
      conditionalRoutes.push(
        <TimeTrackedRoute
          name="conversations#show"
          key="conversations#show"
          exact
          component={Conversation}
          path="/conversations/:id"
          hashingKeys={{ id: "conversations" }}
        />,
      );
    }
  }
  if (SiteSettings.enable_calendars) {
    conditionalRoutes.push(
      <TimeTrackedRoute
        name="calendar"
        path="/calendar/:time"
        exact
        component={AllEntriesWithRouter}
        hashingKeys={{}}
      />,
    );
  }

  const redirects = SiteSettings.user_available_locales.map((lang: string) => (
    <Route key={lang} path={`/${lang}`} component={RedirectToNoLocale} />
  ));
  conditionalRoutes = [...conditionalRoutes, ...redirects];
  return (
    <Switch>
      <TimeTrackedRoute
        exact
        path="/articles/:id"
        name="articles#show"
        component={Article}
        hashingKeys={{}}
      />
      <TimeTrackedRoute
        path="/articles"
        name="articles#index"
        component={Articles}
        hashingKeys={{}}
      />
      <TimeTrackedRoute
        exact
        path={[
          "/input_form_answers/:id",
          "/input_form_answers/:id/:section_id/:direction",
        ]}
        name="input_form_answers#show"
        component={InputForm}
        hashingKeys={{ id: "input_form_answers" }}
      />
      <TimeTrackedRoute
        name="client#edit"
        path="/profile"
        component={Profile}
        hashingKeys={{}}
      />
      <TimeTrackedRoute
        name="dashboard"
        exact
        component={ClientDashboard}
        path="/dashboard"
        hashingKeys={{}}
      />
      <TimeTrackedRoute
        name="embeddable_views#index"
        exact
        component={EmbeddableViews}
        path="/embeddable_views/:name"
        hashingKeys={{}}
      />
      <TimeTrackedRoute
        exact
        path="/compare/:id"
        name="compare#show"
        component={CompareAnswers}
        hashingKeys={{ id: "input_form_answers" }}
      />
      <TimeTrackedRoute
        exact
        path="/input_form_answers/:id/feedback"
        name="feedback#show"
        component={Feedback}
        hashingKeys={{ id: "input_form_answers" }}
      />

      {conditionalRoutes}

      <TimeTrackedRoute
        exact
        path="/"
        name="welcome_client"
        component={WelcomeClient}
        hashingKeys={{}}
      />

      <TimeTrackedRoute
        path=""
        name="error#404"
        component={Error404}
        hashingKeys={{}}
      />
    </Switch>
  );
};

export const ScrollToAnchor = () => {
  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);

      setTimeout(() => {
        const element = document.getElementById(lastHash.current);

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          history.replaceState({}, "", "#");
        }
      }, 1200);
    }
  }, [location]);

  return null;
};
