import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";

import { SiteSettings } from "common/utils/holvikaari";
import {
  CalendarListItem,
  EntryContainer,
  EventInfo,
  TimeInfo,
  TitleAndOther,
} from "./Calendar.styles";
import { CalendarEntry as TCalendarEntry } from "shared/models/calendarEntries";

type person = { name: string; title?: string };

const addMargin = (entry: { personnel: string[]; location?: string }) => {
  if (entry.personnel || entry.location) return true;
  return false;
};

const timeInTimezone = (deviceTimezone: string, appt_timezone?: string) => {
  const longTimezone = appt_timezone ?? deviceTimezone;

  return {
    long: longTimezone,
    short: String(longTimezone.split("/").pop()?.replace(/_/g, " ")),
  };
};

const personnelList = (personnel: string[]) =>
  personnel.map((person, id) => {
    const personInfo: person = JSON.parse(person);
    const title = personInfo.title ? `, ${personInfo.title}` : "";
    return <div key={"person" + id}>{`${personInfo.name}${title}`}</div>;
  });

export function CalendarEntry({
  entry: {
    begin_at,
    title,
    end_at,
    date_only,
    personnel,
    location,
    appt_timezone,
  },
  show_all_timezones,
  deviceTimezone,
}: {
  entry: TCalendarEntry;
  deviceTimezone: string;
  show_all_timezones: boolean; // determines whether timezones are shown for all appointments
}) {
  const intl = useIntl();

  const timezoneInfo = timeInTimezone(deviceTimezone, appt_timezone);
  const beginAt = dayjs.utc(begin_at).tz(timezoneInfo.long);
  const showTimezoneName = date_only // ultimately the override
    ? false // if date_only, do not show TZ
    : show_all_timezones || appt_timezone !== deviceTimezone; // otherwise, verify conditions

  const timeZoneText = showTimezoneName ? (
    <FormattedMessage
      id="timezone"
      values={{
        name: timezoneInfo.short,
      }}
    />
  ) : (
    ""
  );

  const durationText = () => {
    let hours = dayjs(end_at).hour() - dayjs(begin_at).hour();
    if (hours < 0) {
      hours += 24;
    }
    let minutes = dayjs(end_at).minute() - dayjs(begin_at).minute();
    if (minutes < 0) {
      minutes += 60;
      hours -= 1;
    }

    const hoursString =
      hours !== 0
        ? intl.formatMessage({ id: "calendar.hours" }, { hours })
        : "";

    const minutesString =
      minutes !== 0
        ? intl.formatMessage({ id: "calendar.minutes" }, { minutes })
        : "";

    const rtl = document.documentElement.dir === "rtl";

    return (
      <FormattedMessage
        id="calendar.duration"
        values={{
          duration: (rtl
            ? [minutesString, hoursString]
            : [hoursString, minutesString]
          ).join(" "),
        }}
      />
    );
  };

  return (
    <CalendarListItem
      spacing="xs"
      alignItems="flex-start"
      data-testid={`calendar_event`}
    >
      <EntryContainer>
        <TimeInfo bottomMargin={addMargin({ personnel, location })}>
          <strong>
            <div>{beginAt.format("ll")}</div>
          </strong>
          <div>{beginAt.format("dddd")}</div>
          <div>{beginAt.format("LT")}</div>
          <span>{showTimezoneName && timeZoneText}</span>
        </TimeInfo>
        <TitleAndOther>
          <strong>{title}</strong>
          <EventInfo>
            {SiteSettings.calendar_extra_fields.includes("practitioner") &&
              personnel &&
              personnel.length !== 0 && (
                <div data-testid="calendar-personnel">
                  {personnelList(personnel)}
                </div>
              )}
            {SiteSettings.calendar_extra_fields.includes("location") &&
              location && <div data-testid="calendar-location">{location}</div>}
            {durationText()}
          </EventInfo>
        </TitleAndOther>
      </EntryContainer>
    </CalendarListItem>
  );
}
