import {
  concat,
  defer,
  EMPTY,
  from,
  merge,
  Observable,
  of,
  throwError,
} from "rxjs";
import {
  Direction,
  InputFormAction,
  ChangeSectionAction,
  SyncQuestionsAction,
  getSection,
  lockSection,
} from "shared/actions/inputForm";
import { flatten, isEmpty, get } from "lodash";
import { getValueType, getVisibleQuestions } from "shared/utils/inputForm";
import InputFormAnswerSync from "../utils/InputFormAnswerSync";
import { put } from "common/utils/api";
import {
  catchError,
  mergeMap,
  map,
  pluck,
  switchMap,
  withLatestFrom,
  concatMap,
  takeUntil,
  filter as filterRxjs,
  mapTo,
  startWith,
  finalize,
} from "rxjs/operators";
import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { State } from "store";
import {
  InputForm,
  Question,
  SubQuestion,
} from "shared/models/inputForm.types";
import { CriticalErrorAction } from "shared/actions/app";
import dayjs from "dayjs";

type SyncData = SyncQuestionsAction["data"];

export const getInitialSections = (action$: ActionsObservable<any>) =>
  action$.pipe(
    ofType("SET_FORM"),
    mergeMap(({ data }) => {
      return merge([
        getSection(data.links.sections[data.initial_section_id], true, false),
      ]);
    }),
  );

export const inputFormStateMachine = (
  action$: ActionsObservable<any>,
  state$: StateObservable<State>,
) =>
  action$.pipe(
    ofType("SET_FORM"),
    concatMap(action => {
      const inputFormAnswerSync = new InputFormAnswerSync(
        action.data.answer_id,
      );
      const inputFormAnswer$ = inputFormPersistenceStream(
        action$,
        inputFormAnswerSync,
      );
      const changeSection$ = sectionChangeStream(
        action$,
        state$,
        inputFormAnswerSync,
      );
      return merge(
        changeSection$,
        merge(inputFormAnswer$, optimisticUpdateStream(action$)),
      ).pipe(takeUntil(action$.ofType("LEAVE_FORM")));
    }),
  );

const isBatchAnswerUpdate = (action: SyncQuestionsAction) =>
  action.data.length > 1;

const optimisticUpdateStream = (
  action$: ActionsObservable<SyncQuestionsAction>,
) =>
  action$.pipe(
    ofType("SYNC_QUESTIONS"),
    switchMap(action =>
      isBatchAnswerUpdate(action)
        ? EMPTY
        : of({
            type: "SET_OPTIMISTIC_FORM_ANSWER_STATUS",
            data: action.data[0],
          }),
    ),
  );

export const latestValuePerGroup = (
  data$: Observable<SyncData>,
  getGroupId: (data: SyncData) => string,
  toObservable: (
    data: SyncData,
  ) => Observable<InputFormAction | CriticalErrorAction>,
) => {
  // Denote data points with index for checking order.
  const ordered$ = data$.pipe(
    map((d, index) => {
      return { order: index, data: d };
    }),
  );

  const latest: Record<string, number> = {};

  // Save the latest received data point index per group.
  const latestSubscription = ordered$.subscribe(d => {
    latest[getGroupId(d.data)] = d.order;
  });

  // Process each data point in a queued manner. Skip data point
  // for which there's a more recent data point in the same group.
  return ordered$.pipe(
    concatMap(od =>
      latest[getGroupId(od.data)] > od.order ? EMPTY : toObservable(od.data),
    ),
    finalize(() => latestSubscription.unsubscribe()),
  );
};

const toActionAndFlag = <T,>(action: T, hasNewAnswer: boolean) => ({
  action,
  hasNewAnswer,
});

const passAction = ({
  action,
  hasNewAnswer,
}: {
  action: InputFormAction | CriticalErrorAction;
  hasNewAnswer: boolean;
}) => !(action.type === "SET_FORM_ANSWER_STATUS" && hasNewAnswer);

const questionIdFrom = (actionData: SyncData) =>
  actionData[0][0].external_id.toString();

const inputFormPersistenceStream = (
  action$: ActionsObservable<SyncQuestionsAction>,
  inputFormAnswerSync: InputFormAnswerSync,
) => {
  // a and b are question id, number after describes the answer.
  // Desired: skip a2 since there's already a3 available
  // In : a1--b1--a2--a3
  // Out: a1----->b1----->a3------>

  const syncData$ = action$.pipe(ofType("SYNC_QUESTIONS"), pluck("data"));
  const updateQuestionIds$ = syncData$.pipe(map(questionIdFrom));

  return latestValuePerGroup(syncData$, questionIdFrom, data => {
    // In case there's a new answer to the question that the request
    // is processing, wait the request to finish but do not forward
    // the UI action (so that the UI does not playback the changes).
    // The purpose is to have only one in-flight request going on
    // at a time.
    const sameQuestionUpdated$ = updateQuestionIds$.pipe(
      filterRxjs(questionId => questionIdFrom(data) === questionId),
      mapTo(true),
      startWith(false),
    );

    return lockedSectionUpdate(inputFormAnswerSync.queueAnswers(data)).pipe(
      withLatestFrom(sameQuestionUpdated$, toActionAndFlag),
      filterRxjs(passAction),
      pluck("action"),
    );
  });
};

const lockedSectionUpdate = <T,>(request: Observable<T> | Promise<T>) =>
  concat(
    of(lockSection(true)),
    defer(() => from(request)),
    of(lockSection(false)),
  );

const unlockAndErrorState = (errorState: any) =>
  concat(of(lockSection(false)), of(errorState));

const sectionChangeStream = (
  action$: ActionsObservable<ChangeSectionAction>,
  state$: StateObservable<State>,
  inputFormAnswerSync: any,
) =>
  action$.pipe(
    ofType("CHANGE_SECTION"),
    mergeMap(action => {
      const inputFormState = state$.value.inputForm;

      // on previous section navigation only section fetch is necessary
      if (action.data.direction === Direction.previous) {
        return getSection(action.data.url);
      }

      // On next section navigation the empty question answers need to be
      // handled in case of empty required answers.
      // On success next section is fetched.
      if (action.data.direction === Direction.next) {
        return lockedSectionUpdate(
          concat(
            handleEmptyQuestions(inputFormState, inputFormAnswerSync),
            getSection(action.data.url),
          ),
        ).pipe(catchError(unlockAndErrorState));
      }
      // On finishing the update of empty sections is needed before sending the
      // completion request of the input form answer.
      // On success the redirect state is returned in the input form state
      if (action.data.direction === Direction.finish) {
        return lockedSectionUpdate(
          handleEmptyQuestions(inputFormState, inputFormAnswerSync).pipe(
            switchMap(() =>
              from(
                put(inputFormState.links.finish, {
                  local_time: dayjs.utc().local().format(),
                }).then(completeResponse =>
                  onFormCompleted(state$, completeResponse),
                ),
              ),
            ),
            catchError(unlockAndErrorState),
          ),
        );
      }
      return throwError(
        `${action.type} was called with invalid data.direction value ${action.data.direction}`,
      );
    }),
  );

// Submit empty responses to all unfilled questions
const handleEmptyQuestions = (
  formState: InputForm,
  inputFormAnswerSync: InputFormAnswerSync,
) => {
  // Send default answers
  return from(
    inputFormAnswerSync.queueAnswers(questionRequests(formState)),
  ).pipe(
    map(newState => {
      if (!isEmpty(get(newState, "data.dp_errors"))) {
        // on error throw with the state containing the errors to halt the
        // observable pipeline
        throw newState;
      }
      return newState;
    }),
  );
};

const getValue = (q: Question | SubQuestion) =>
  q?.data_point?.[getValueType(q as Question, false)];

const questionRequests = (formState: InputForm) =>
  // Get MulptipleValue parent questions to check if children still satisfy validations
  // Get all MultipleValue sub questions, because they are weird af and I can't even.
  // Get parent question last to make sure children are updated before validating parent.

  // KAIKU-1779: Use the data existing data point value only if there's no errors so that the recent
  // value can be validated first.
  visibleQuestions(formState)
    .sort(q => (q.sub_question_ids ? 1 : -1))
    .map((q): [Question | SubQuestion, any, boolean] => [
      q,
      q.data_point ? getValue(q) : null,
      false,
    ]);

const visibleQuestions = (formState: InputForm) => {
  const unflattened = getVisibleQuestions(
    formState,
    activeSection(formState).questions,
  ).map(q => (q.sub_questions ? flatten([q, q.sub_questions]) : q));
  return flatten<any>(unflattened);
};

const activeSection = (formState: InputForm) => {
  return formState.sections[formState.activeSection];
};

const onFormCompleted = (
  state$: StateObservable<State>,
  finishedResponse: any,
) => {
  if (finishedResponse.completed) {
    const globalState = state$.value;
    return {
      type: "FINISH_FORM_ANSWER",
      data: { finishedResponse, app: globalState.app, user: globalState.user },
    };
  }
};
