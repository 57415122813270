import React from "react";
import {
  HorizontalDividerContainer,
  HorizontalDividerLine,
} from "./HorizontalDivider.styles";
interface IProps {
  children?: JSX.Element | (JSX.Element | null)[];
  margin?: string;
  testID?: string;
}

export const HorizontalDivider = (props: IProps) => {
  const { children, margin = "2ex", testID } = props;

  return (
    <HorizontalDividerContainer>
      <HorizontalDividerLine marginRight={margin} data-testid={testID} />
      {children}
      <HorizontalDividerLine marginLeft={margin} data-testid={testID} />
    </HorizontalDividerContainer>
  );
};
